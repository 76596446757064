import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "@/plugins/log.js"
import "@/plugins/iview.js"
import "@/plugins/title.js"
import "@/plugins/img.js"
import "@/plugins/authority.js"
import "@/style/main.less"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
