<template>
  <div class="dingdangLogo">
    <img src="@/assets/logo60.png" />
    U & Earth
  </div>
</template>

<script>
export default {
  name: "Dingdang",
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>

<style>
.dingdangLogo {
  width: 100%;
  padding: 10px 0;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  text-align: center;
  font-size: 24px;
}
.dingdangLogo img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  vertical-align: -7px;
}
</style>
