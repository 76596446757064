import Vue from "vue"
import VueRouter from "vue-router"
import Main from "../views/Main.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Main,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/item/cates",
        name: "CategoryQueryPage",
        component: () => import(/* webpackChunkName: "categoryQueryPage" */ "../views/item/CategoryQueryPage.vue"),
      },
      {
        path: "/item/query",
        name: "ItemQueryPage",
        component: () => import(/* webpackChunkName: "itemQueryPage" */ "../views/item/ItemQueryPage.vue"),
      },
      {
        path: "/item/publish",
        name: "ItemPublishPage",
        component: () => import(/* webpackChunkName: "itemPublishPage" */ "../views/item/ItemPublishPage.vue"),
      },
      {
        path: "/store/cates",
        name: "StoreCategoryQueryPage",
        component: () => import(/* webpackChunkName: "categoryQueryPage" */ "../views/store/BusinessType.vue"),
      },
      {
        path: "/store/query",
        name: "StoreQueryPage",
        component: () => import(/* webpackChunkName: "StoreQueryPage" */ "../views/store/StoreQueryPage.vue"),
      },
      {
        path: "/store/publish",
        name: "StorePublishPage",
        component: () => import(/* webpackChunkName: "StorePublishPage" */ "../views/store/StorePublishPage.vue"),
      },
      {
        path: "/tx/receipts",
        name: "ReceiptQueryPage",
        component: () => import(/* webpackChunkName: "receiptQueryPage" */ "../views/tx/ReceiptQueryPage.vue"),
      },
      {
        path: "/tx/orders",
        name: "OrderQueryPage",
        component: () => import(/* webpackChunkName: "orderQueryPage" */ "../views/tx/OrderQueryPage.vue"),
      },
      {
        path: "/tx/order",
        name: "OrderDetailPage",
        component: () => import(/* webpackChunkName: "orderDetailPage" */ "../views/tx/OrderDetailPage.vue"),
      },
      {
        path: "/tx/shipments",
        name: "ShipmentQueryPage",
        component: () => import(/* webpackChunkName: "shipmentQueryPage" */ "../views/tx/ShipmentQueryPage.vue"),
      },
      {
        path: "/operation/plan",
        name: "PlanQueryPage",
        component: () => import(/* webpackChunkName: "planQueryPage" */ "../views/operation/PlanQueryPage.vue"),
      },
      {
        path: "/bill/withdraw",
        name: "BillWithdrawQueryPage",
        component: () =>
          import(/* webpackChunkName: "billWithdrawQueryPage" */ "../views/bill/BillWithdrawQueryPage.vue"),
      },
      {
        path: "/operation/notifications",
        name: "NotificationQueryPage",
        component: () =>
          import(/* webpackChunkName: "notificationQueryPage" */ "../views/operation/NotificationQueryPage.vue"),
      },
      {
        path: "/operation/ui",
        name: "UISettingsPage",
        component: () => import(/* webpackChunkName: "uiSettingsPage" */ "../views/operation/UISettingsPage.vue"),
      },
      {
        path: "/media/query",
        name: "MediaQueryPage",
        component: () => import(/* webpackChunkName: "mediaQueryPage" */ "../views/media/MediaQueryPage.vue"),
      },
      {
        path: "/user/query",
        name: "UserQueryPage",
        component: () => import(/* webpackChunkName: "userQuery" */ "../views/user/UserQueryPage.vue"),
      },
      {
        path: "/analysis/summary",
        name: "AnalysisSummaryPage",
        component: () =>
          import(/* webpackChunkName: "analysisSummaryPage" */ "../views/analysis/AnalysisSummaryPage.vue"),
      },
      {
        path: "/analysis/order",
        name: "AnalysisOrderPage",
        component: () => import(/* webpackChunkName: "analysisOrderPage" */ "../views/analysis/AnalysisOrderPage.vue"),
      },
      {
        path: "/analysis/plans",
        name: "AnalysisPlanPage",
        component: () => import(/* webpackChunkName: "analysisPlanPage" */ "../views/analysis/AnalysisPlanPage.vue"),
      },
      {
        path: "/analysis/source",
        name: "AnalysisSourcePage",
        component: () =>
          import(/* webpackChunkName: "analysisSourcePage" */ "../views/analysis/AnalysisSourcePage.vue"),
      },
      {
        path: "/analysis/location",
        name: "AnalysisLocationPage",
        component: () =>
          import(/* webpackChunkName: "analysisLocationPage" */ "../views/analysis/AnalysisLocationPage.vue"),
      },
      {
        path: "/system/admin",
        name: "Admin",
        component: () => import(/* webpackChunkName: "adminQueryPage" */ "../views/system/AdminQueryPage.vue"),
      },
      {
        path: "/system/role",
        name: "Role",
        component: () => import(/* webpackChunkName: "roleQueryPage" */ "../views/system/RoleQueryPage.vue"),
      },
      {
        path: "/violation/itemViolation",
        name: "ItemViolationQueryPage",
        component: () =>
          import(/* webpackChunkName: "itemViolationQueryPage" */ "../views/violation/ItemViolationQueryPage.vue"),
      },
      {
        path: "/system/appSettings",
        name: "AppSettingsPage",
        component: () => import(/* webpackChunkName: "appSettingsPage" */ "../views/system/AppSettingsPage.vue"),
      },
      {
        path: "/promo/create",
        name: "PromoCodeCreatePage",
        component: () => import(/*webpackChunkName: "promocodeCreatePage" */"../views/promocode/PromoCodeCreatePage"),
      },
      {
        path: "/promo/query",
        name: "PromoCodeQueryPage",
        component: () => import(/*webpackChunkName: "promocodeQueryPage" */"../views/promocode/PromoCodeQueryPage"),
      },
      {
        path: "/groupbuy/create",
        name: "GroupbuyCreatePage",
        component: () => import(/*webpackChunkName: "GroupbuyCreatePage" */"../views/groupbuy/GroupbuyCreatePage"),
      },
      {
        path: "/groupbuy/query",
        name: "GroupbuyQueryPage",
        component: () => import(/*webpackChunkName: "GroupbuyCodeQueryPage" */"../views/groupbuy/GroupbuyQueryPage"),
      },
      {
        path: "/article/list",
        name: "ArticleList",
        component: () => import(/*webpackChunkName: "promocodeQueryPage" */"../views/article/ArticleList"),
      },
      {
        path: "/system/appIndex",
        name: "AppIndexPage",
        component: () => import(/* webpackChunkName: "AppIndexPage" */ "../views/system/AppIndexPage.vue"),
      },
      {
        path: "/operation/itemBlacklist",
        name: "ItemBlacklistPage",
        component: () => import(/*webpackChunkName: "ItemBlacklistPage"*/ "../views/operation/ItemBlacklist"),
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      loginRequired: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  { path: "*", redirect: "/login" },
]

const router = new VueRouter({
  routes,
  mode: "history",
})

export default router
