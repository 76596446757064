<template>
  <Menu
    ref="navMenu"
    :active-name="activatedMenuItem"
    :open-names="openedMenus"
    theme="dark"
    width="200px"
    @on-select="menuSelected"
  >
    <MenuItem name="/dashboard">
      <Icon type="ios-apps" />
      仪表盘
    </MenuItem>
    <Submenu name="/item" v-if="$anyPermission(['CATE_QUERY', 'ITEM_QUERY', 'ITEM_EDIT'])">
      <template slot="title">
        <Icon type="ios-pricetag" />
        商品
      </template>
      <MenuItem name="/item/cates" v-if="$permission('CATE_QUERY')">分类管理</MenuItem>
      <MenuItem name="/item/query" v-if="$permission('ITEM_QUERY')">商品管理</MenuItem>
    </Submenu>
    <Submenu name="/store" v-if="$anyPermission(['CATE_QUERY', 'ITEM_QUERY', 'ITEM_EDIT'])">
      <template slot="title">
        <Icon type="ios-pricetag" />
        店铺管理
      </template>
      <MenuItem name="/store/cates" v-if="$permission('CATE_QUERY')">业态分类</MenuItem>
      <MenuItem name="/store/query" v-if="$permission('ITEM_QUERY')">到店店铺</MenuItem>
    </Submenu>
    <Submenu name="/tx" v-if="$anyPermission(['ORDER_QUERY'])">
      <template slot="title">
        <Icon type="ios-cart-outline" />
        交易
      </template>
      <MenuItem name="/tx/receipts" v-if="$permission('ORDER_QUERY')">支付流水</MenuItem>
      <MenuItem name="/tx/orders" v-if="$permission('ORDER_QUERY')">订单管理</MenuItem>
      <MenuItem name="/tx/shipments" v-if="$permission('ORDER_QUERY')">物流管理</MenuItem>
    </Submenu>
    <Submenu name="/bill" v-if="$anyPermission(['FINANCE_QUERY'])">
      <template slot="title">
        <Icon type="ios-cash" />
        财务
      </template>
      <MenuItem name="/bill/withdraw" v-if="$permission('FINANCE_QUERY')">提现申请</MenuItem>
    </Submenu>
    <Submenu name="/operation" v-if="$anyPermission(['POSITION_QUERY', 'UI_EDIT', 'PUSH_QUERY'])">
      <template slot="title">
        <Icon type="ios-calendar" />
        运营
      </template>
      <MenuItem name="/operation/plan" v-if="$permission('POSITION_QUERY')">运营位</MenuItem>
      <MenuItem name="/operation/ui" v-if="$permission('UI_EDIT')">界面调整</MenuItem>
      <!-- <MenuItem name="/operation/article">文章</MenuItem> -->
      <MenuItem name="/operation/notifications" v-if="$permission('PUSH_QUERY')">推送消息</MenuItem>
      <MenuItem name="/operation/itemBlacklist" v-if="$permission('PUSH_QUERY')">商品发布敏感词</MenuItem>
    </Submenu>
    <Submenu name="/media" v-if="$anyPermission(['MEDIA_QUERY'])">
      <template slot="title">
        <Icon type="ios-attach" />
        文件
      </template>
      <MenuItem name="/media/query">媒体文件</MenuItem>
    </Submenu>
    <Submenu name="/analysis" v-if="$anyPermission(['ANALYTICS'])">
      <template slot="title">
        <Icon type="ios-analytics" />
        分析
      </template>
      <MenuItem name="/analysis/summary">概览</MenuItem>
      <MenuItem name="/analysis/order">每日交易数据</MenuItem>
      <!-- <MenuItem name="/analysis/plans">运营位分析</MenuItem> -->
      <!-- <MenuItem name="/analysis/source">发布来源</MenuItem> -->
      <MenuItem name="/analysis/location">地理位置分布</MenuItem>
    </Submenu>
    <Submenu name="/user" v-if="$anyPermission(['USER_QUERY'])">
      <template slot="title">
        <Icon type="ios-contacts" />
        用户和会员
      </template>
      <MenuItem name="/user/query">用户管理</MenuItem>
    </Submenu>
    <Submenu name="/system" v-if="$anyPermission(['ADMIN_USER_QUERY', 'ROLE_QUERY', 'CONFIGURATION_QUERY'])">
      <template slot="title">
        <Icon type="ios-appstore" />
        系统管理
      </template>
      <MenuItem name="/system/admin" v-if="$permission('ADMIN_USER_QUERY')">
        管理员管理
      </MenuItem>
      <MenuItem name="/system/role" v-if="$permission('ROLE_QUERY')">
        角色管理
      </MenuItem>
      <MenuItem name="/system/appSettings" v-if="$permission('CONFIGURATION_QUERY')">
        应用相关配置
      </MenuItem>
      <MenuItem name="/system/appIndex" v-if="$permission('CONFIGURATION_REINDEX')">
        应用索引
      </MenuItem>
    </Submenu>
    <Submenu name="/promo" v-if="$anyPermission(['PROMO_CREATE', 'PROMO_EDIT', 'PROMO_DEL'])">
      <template slot="title">
        <Icon type="ios-cash" />
        优惠码
      </template>
      <MenuItem name="/promo/create" v-if="$permission('PROMO_CREATE')">
        生成优惠码
      </MenuItem>
      <MenuItem name="/promo/query" v-if="$permission('PROMO_QUERY')">
        查看优惠码
      </MenuItem>
    </Submenu>
    <Submenu name="/groupbuy" v-if="$anyPermission(['PROMO_CREATE', 'PROMO_EDIT', 'PROMO_DEL'])">
      <template slot="title">
        <Icon type="md-reorder" />
        集单
      </template>
      <MenuItem name="/groupbuy/create" v-if="$permission('PROMO_CREATE')">
        生成集单
      </MenuItem>
      <MenuItem name="/groupbuy/query" v-if="$permission('PROMO_QUERY')">
        查看集单
      </MenuItem>
    </Submenu>
    <Submenu name="/violation" v-if="$permission('VIOLATION_QUERY')">
      <template slot="title">
        <Icon type="ios-nuclear" />
        举报
      </template>
      <MenuItem name="/violation/itemViolation">商品举报</MenuItem>
    </Submenu>
    <Submenu name="/article" v-if="$permission('VIOLATION_QUERY')">
      <template slot="title">
        <Icon type="ios-paper" />
        文章管理
      </template>
      <MenuItem name="/article/list">文章列表</MenuItem>
    </Submenu>
    <MenuItem name="/logout">
      <Icon type="ios-exit" />
      登出
    </MenuItem>
  </Menu>
</template>

<script>
export default {
  name: "NavMenu",
  components: {},
  data() {
    return {
      activatedMenuItem: "",
      openedMenus: [],
    }
  },
  computed: {},
  watch: {
    $route() {
      this.init()
    },
  },
  async mounted() {
    this.init()
    await this.reload()
  },
  methods: {
    init() {
      this.activatedMenuItem = this.$route.path
      const name = "/" + this.activatedMenuItem.split("/")[1]
      this.openedMenus = [name]
      this.$nextTick(() => {
        this.$refs.navMenu.updateOpened()
      })
    },
    async reload() {
    },
    menuSelected(name) {
      if (name === this.activatedMenuItem) return
      this.$router.push({ path: name })
    },
  },
}
</script>
