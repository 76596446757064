<template>
  <Layout class="main">
    <Sider hide-trigger width="200px" style="min-width: 200px">
      <Dingdang />
      <NavMenu />
    </Sider>
    <Content>
      <div class="contentWrapper">
        <router-view />
      </div>
    </Content>
  </Layout>
</template>

<script>
// @ is an alias to /src
import Dingdang from "@/components/Dingdang.vue"
import NavMenu from "@/components/NavMenu.vue"

export default {
  name: "Main",
  created: function() {
    this.checkUser()
  },
  components: {
    Dingdang,
    NavMenu,
  },
  methods: {
    checkUser: function() {
      let token = sessionStorage.getItem("token")
      if (token === null || token === "undefined") {
        this.$router.replace({ name: "Login" })
      }
    },
  },
}
</script>

<style>
.main {
  height: 100%;
}
.contentWrapper {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.contentWrapper:first-child {
  height: auto;
  overflow-y: auto;
}
</style>
