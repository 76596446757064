import Vue from "vue"
import store from "@/store"

Vue.prototype.$anyPermission = asked =>
  asked.reduce((acc, item) => {
    if (acc) {
      return acc
    }
    return store.state.authorities.includes(item)
  }, false)

Vue.prototype.$permission = asked => store.state.authorities.includes(asked)
