import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ encodingType: "aes", isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: {
    authorities: null,
    admin: null,
  },
  mutations: {
    setAuthorities(state, payload) {
      state.authorities = payload
    },
    setAdmin(state, payload) {
      state.admin = payload
    },
    clearStorage(state) {
      state.authorities = []
      state.admin = {}
    },
  },
  actions: {
    setAuthorities({ commit }, payload) {
      commit("setAuthorities", payload)
    },
    setAdmin({ commit }, payload) {
      commit("setAdmin", payload)
    },
    clearStorage({ commit }, payload) {
      commit("clearStorage", payload)
    },
  },
  modules: {},
})
