import Vue from "vue"

Vue.directive("title", {
  inserted(el) {
    if (el.dataset.title) {
      document.title = `${el.dataset.title} - 后台管理`
    } else {
      document.title = "后台管理"
    }
  },
  update(el) {
    if (el.dataset.title) {
      document.title = `${el.dataset.title} - 后台管理`
    } else {
      document.title = "后台管理"
    }
  },
})
