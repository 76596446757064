import Vue from "vue"

import noimage from "@/assets/noimage.png"

Vue.directive("real-img", async function(el, binding) {
  el.setAttribute("src", noimage)
  let imgURL = binding.value
  if (imgURL) {
    let exist = await imageIsExist(imgURL)
    if (exist) {
      el.setAttribute("src", imgURL)
    }
  }
})

let imageIsExist = function(url) {
  return new Promise((resolve) => {
    var img = new Image()
    img.onload = function() {
      if (this.complete == true) {
        resolve(true)
        img = null
      }
    }
    img.onerror = function() {
      resolve(false)
      img = null
    }
    img.src = url
  })
}
